@use "sass:meta";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/cookieconsent/build/cookieconsent.min.css";
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
$body-font-family: Lato;

$foundation-palette: (
    primary: #f26c5b,
    secondary: #2e353d,
    success: #3adb76,
    warning: #ffae00,
    alert: #ec5840,
) !default;

$light-gray: #2e353d;

$tab-active-color: #ffffff;
$tab-item-font-size: 1.0em;
$tab-item-background-hover: #e9ebec;

// Import Foundation for Sites
// See https://foundation.zurb.com/sites/docs/sass.html for detailed info.
@import 'foundation-sites/scss/foundation';
@include foundation-everything;

// Import bootstrap styles
@import "bootstrap/scss/bootstrap";

// Import ngx-bootstrap Framework added styles
@import "ngx-bootstrap/datepicker/bs-datepicker";

// Import font awesome
$fa-font-path: "font-awesome/fonts";
@import "font-awesome/scss/font-awesome";

html, body {
    height: 100%;
}

.page-wrap {
    min-height: 100;
    margin-bottom: --160px;
}

footer {
    bottom: 0em;
}

h2, h3, h4 {
    font-family: Lato;
    font-weight: 300;
}

h3 {
    font-family: Lato;
    color: #f26c5b;
    font-weight: 700;
}
